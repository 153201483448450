import React from 'react';
import { css } from '@emotion/css';
import { FC } from 'react';

type Props = {
  value: string | null;
};

export const CTA: FC<Props> = ({ value }) => (
  <div
    className={css`
      text-transform: uppercase;
      padding: 8px 8px 0 8px;
      color: black;
      font-weight: bold;
      line-height: 1.1rem;
      font-size: 0.8rem;
    `}
  >
    {value}
  </div>
);
