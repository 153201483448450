import { API } from '../../constants';
import { AdDisplayStat } from './types';

export const sendDisplaysDataApi = async (
  data: AdDisplayStat[],
  deviceId: string,
) => {
  if (!API) return null;

  try {
    await fetch(`${API}/v1/stats/ad`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', deviceId },
      body: JSON.stringify(data),
    });
  } catch (e: any) {
    console.log('Error: ', e);
  }
};
