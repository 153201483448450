import { css } from '@emotion/css';
import { FC, useEffect, useState } from 'react';
import { Ad } from '../../../types';
import { DebugItem } from './DebugItem';
import { getLastPart } from '../helpers';

type Props = {
  ad: Ad;
  activeSlideIndex?: number;
};

export const Debug: FC<Props> = ({ ad, activeSlideIndex }) => {
  const [delay, setDelay] = useState<number>(0);

  useEffect(() => {
    const slideDelay = ad.adDurationInSeconds * 1000;
    setDelay(slideDelay / 1000);
  }, [ad, activeSlideIndex]);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | null = null;

    if (delay) {
      interval = setInterval(() => {
        setDelay(current => (current > 0 ? current - 1 : current));
      }, 1000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [delay, ad]);

  return (
    <div
      className={css`
        position: absolute;
        z-index: 3;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        font-size: 2rem;
      `}
    >
      <div
        className={css`
          position: absolute;
          right: 30px;
          top: 30px;
          display: flex;
        `}
      >
        <DebugItem label="Duration" value={`${delay}`} />
        <DebugItem label="ID" value={getLastPart(ad.id, 8)} />
      </div>
    </div>
  );
};
