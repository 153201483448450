import { useEffect, useRef, useState } from 'react';
import { Ad } from '../../types';
import { sendDisplaysDataApi } from './api';
import { AdDisplayStat } from './types';

export const useStats = (ads: Ad[], deviceId?: string) => {
  const [totalDisplaysCount, setTotalDisplaysCount] = useState<number>(0);
  const displaysStats = useRef<AdDisplayStat[]>([]);

  const resetCount = () => {
    console.log('resetCount');
    setTotalDisplaysCount(0);
    resetStats();
  };

  const resetStats = () => {
    console.log('resetStats');
    const stats = displaysStats.current;
    displaysStats.current = stats.map(stat => {
      return {
        ...stat,
        displaysCount: 0,
      };
    });
  };

  const sendStats = () => {
    console.log('sendStats');
    if (deviceId) sendDisplaysDataApi(displaysStats.current, deviceId);
  };

  const generateInitialAdStats = (ads: Ad[]) => {
    console.log('generateInitialAdStats');
    displaysStats.current = ads.reduce((acc: AdDisplayStat[], ad) => {
      acc.push({
        type: 'displays',
        adId: ad.id,
        displaysCount: 0,
      });

      return acc;
    }, []);
  };

  const incrementStats = (activeIndex: number) => {
    console.log('incrementStats');
    setTotalDisplaysCount(current => current + 1);

    displaysStats.current = displaysStats.current.map(it => {
      if (it.adId === ads[activeIndex].id) {
        it.displaysCount = it.displaysCount + 1;
      }
      return it;
    });
  };

  useEffect(() => {
    // TODO: API displaysSendLimit
    if (totalDisplaysCount === 3) {
      sendStats();
      resetCount();
    }
  }, [totalDisplaysCount]);

  useEffect(() => {
    if (ads.length) {
      generateInitialAdStats(ads);
    }
  }, [ads]);

  return {
    resetCount,
    sendStats,
    incrementStats,
  };
};
