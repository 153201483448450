import { API } from './constants';
import { Ad } from './types';

export const getAds = async (
  deviceId: string,
  zoneId: number,
): Promise<Ad[]> => {
  try {
    const response = await fetch(`${API}/v1/zone/${zoneId}/ads`, {
      headers: {
        deviceId,
      },
    });
    return response.json();
  } catch (e) {
    console.log(e);
    return [];
  }
};
