import { css } from '@emotion/css';
import { FC } from 'react';

type Props = {
  label: string;
  value: string;
};

export const DebugItem: FC<Props> = ({ label, value }) => (
  <div
    className={css`
      font-size: 1.4rem;
      display: flex;
      flex-direction: column;
      width: 200px;
      margin: 5px;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.8);
      border-radius: 10px;
    `}
  >
    <span>{label}</span>
    <span
      className={css`
        font-size: 2rem;
      `}
    >
      {value}
    </span>
  </div>
);
