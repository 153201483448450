import React from 'react';
import { css } from '@emotion/css';
import { FC } from 'react';
import { CTA } from './CTA';

type Props = {
  url: string;
  callToActionText: string | null;
};

export const QR: FC<Props> = ({ url, callToActionText }) => (
  <div
    className={css`
      position: absolute;
      z-index: 2;
      height: 100vh;
      width: 40%;
      background-image: linear-gradient(
        -45deg,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0) 30%,
        rgba(0, 0, 0, 0) 100%
      );
      bottom: 0;
      right: 0;
      box-sizing: border-box;
      padding: 30px;
    `}
  >
    <div
      className={css`
        background-color: white;
        position: absolute;
        bottom: 30px;
        right: 30px;
        display: block;
        border-radius: 7px;
        overflow: hidden;
        width: 160px;
      `}
    >
      {callToActionText && <CTA value={callToActionText} />}
      <img
        src={url}
        className={css`
          width: inherit;
        `}
        alt=""
      />
    </div>
  </div>
);
