import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper';
import { useEffect, useState } from 'react';
import { VideoSlide } from './VideoSlide';
import { getAds } from './api';
import { Swiper as SwiperType } from 'swiper/types';
import { Ad } from './types';
import { ENV, Environment, TEST_ZONE_ID } from './constants';
import { QR } from './features/qr';
import { useStats } from './features/stats';
import { multiplyArray } from './helper';
import { Debug } from './features/debug';
import React from 'react';

const App = () => {
  const [ads, setAds] = useState<Ad[]>([]);
  const [shuffled, setShuffled] = useState<Ad[]>([]);
  const [deviceId, setDeviceId] = useState<string>();
  const [muted, setMuted] = useState(true);
  // TODO: set delay from api
  const [delay, setDelay] = useState<number>(0);
  const { resetCount, sendStats, incrementStats } = useStats(ads, deviceId);
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>();

  const setDelayByMediaType = (swiper?: SwiperType) => {
    const i = swiper?.activeIndex || 0;
    if (ads.length) {
      setDelay(ads[i].adDurationInSeconds * 1000);
    }
  };

  const onSlideChange = (swiper: SwiperType) => {
    setDelayByMediaType(swiper);
    incrementStats(swiper.activeIndex);
    setActiveSlideIndex(swiper.activeIndex);
  };

  const onBeforeInit = (swiper: SwiperType) => {
    setDelayByMediaType(swiper);
  };

  const prepareAds = async (deviceId: string, zoneId: number) => {
    const receivedAds = await getAds(deviceId, zoneId);
    const activeAds = getActiveAds(receivedAds);
    setDeviceId(deviceId);
    setAds(activeAds);
  };

  const toggleMuted = (isMuted: boolean) => setMuted(isMuted);

  useEffect(() => {
    if (ENV === Environment.Production) {
      (window as any).ReactNativeWebView?.postMessage('APP_INITIAL_LOAD');

      document.addEventListener('message', (message: any) => {
        sendStats();
        resetCount();

        const messageData = JSON.parse(message.data);
        const zoneId = messageData.zoneId;
        const deviceId = messageData.deviceId;

        prepareAds(deviceId, zoneId);
      });
    }

    if (ENV === Environment.Development && TEST_ZONE_ID) {
      prepareAds('kfFB-wukfp_ReXt', parseInt(TEST_ZONE_ID));
    }
  }, []);

  useEffect(() => {
    if (ads.length > 0) {
      (window as any).ReactNativeWebView?.postMessage('ADS_READY');
      const sorted = multiplyArray(ads);
      setShuffled(sorted);
    }
  }, [ads]);

  return shuffled.length ? (
    <>
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        centeredSlides={true}
        autoplay={{
          delay,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, EffectFade]}
        onSlideChange={onSlideChange}
        onBeforeInit={onBeforeInit}
        allowTouchMove={false}
        watchSlidesProgress={true}
        effect="fade"
        speed={300}
      >
        {ads.map((ad: Ad) => {
          const qrCodeUrl = ad.zones?.[0]?.qrCodeUrl || ad.qrCodeUrl;

          return (
            <SwiperSlide key={ad.id}>
              {({ isActive }) => (
                <>
                  {qrCodeUrl && (
                    <QR
                      url={qrCodeUrl}
                      callToActionText={ad.qrCodeDesription}
                    />
                  )}
                  {ENV === Environment.Development && (
                    <Debug ad={ad} activeSlideIndex={activeSlideIndex} />
                  )}
                  {ad.format.includes('video') && (
                    <VideoSlide
                      uri={ad.url}
                      isActive={isActive}
                      muted={muted}
                      toggleMuted={toggleMuted}
                    />
                  )}
                  {ad.format.includes('image') && (
                    <img
                      src={ad.url}
                      alt="ads"
                      style={{
                        height: '100%',
                        width: '100%',
                        backgroundColor: 'black',
                        objectFit: 'contain',
                      }}
                    />
                  )}
                </>
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  ) : null;
};

// TODO: Extract to db config

export const getDelayByMediaType = (type: string) => {
  if (type === 'video30s') {
    return 30000; //ms
  }
  if (type === 'video15s') {
    return 15000; //ms
  }
  if (type === 'image') {
    return 10000; //ms
  }

  return 10000;
};

const getActiveAds = (ads: Ad[]) => {
  return ads.filter(ad => {
    return (
      ad.stats.displaysStats.totalDisplaysCount >
      ad.stats.displaysStats.currentDisplaysCount
    );
  });
};

export default App;
