import { css } from '@emotion/css';
import { FC } from 'react';
import { VscUnmute, VscMute } from 'react-icons/vsc';

type Props = {
  muted: boolean;
  toggleMuted: (muteStatus: boolean) => void;
};

export const VolumeControl: FC<Props> = ({ muted, toggleMuted }) => {
  console.log('🚀 ~ muted:', muted);
  return (
    <div
      data-test-id="volume-control"
      className={css`
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        position: absolute;
        bottom: 30px;
        left: 30px;
        width: 130px;
        height: 130px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 4;
      `}
      onClick={() => toggleMuted(!muted)}
    >
      {muted ? <VscMute size={60} /> : <VscUnmute size={60} />}
    </div>
  );
};
