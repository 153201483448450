import { Ad } from './types';
export const multiplyArray = (ads: Ad[]) => {
  let mult: Ad[] = [];
  // eslint-disable-next-line
  ads.map(item => {
    if (item.zones[0].promotionMultiplier) {
      let promotionMultiplier = item.zones[0].promotionMultiplier;
      while (promotionMultiplier > 0) {
        mult.push(item);
        promotionMultiplier = promotionMultiplier - 1;
      }
    } else {
      mult.push(item);
    }
  });
  const shuffled = mult.sort(() => Math.random() - 0.5);
  return shuffled;
};
