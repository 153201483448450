import { useRef, useEffect, useMemo } from 'react';
import { VolumeControl } from './features/volume';

type Props = {
  uri: string;
  startFrom?: number;
  isActive: boolean;
  muted: boolean;
  toggleMuted: (isMuted: boolean) => void;
};

export const VideoSlide: React.FC<Props> = ({
  uri,
  startFrom,
  isActive,
  muted,
  toggleMuted,
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (isActive) {
      play();
    } else {
      stop();
    }
  }, [isActive]);

  const stop = () => {
    const video = videoRef.current;

    if (video) {
      video.muted = true;
      toggleMuted(true);
      video.pause();
      video.currentTime = startFrom || 0;
    }
  };

  const play = () => {
    const video = videoRef.current;

    if (video) {
      video.muted = true;
      toggleMuted(true);
      const isPlaying =
        video?.currentTime > 0 &&
        !video?.paused &&
        !video?.ended &&
        video?.readyState > video?.HAVE_CURRENT_DATA;
      if (!isPlaying) {
        video.play();
      }
    }
  };

  useMemo(() => {
    const video = videoRef.current;
    if (video) {
      video.muted = muted;
    }
  }, [muted]);

  return (
    <>
      <VolumeControl muted={muted} toggleMuted={() => toggleMuted(!muted)} />
      <video
        disablePictureInPicture
        ref={videoRef}
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'black',
          objectFit: 'contain',
          objectPosition: 'center',
        }}
      >
        <source src={uri} type="video/mp4" />
      </video>
    </>
  );
};
